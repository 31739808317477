import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { ApolloRequestHandler } from '@vayapin/oauth-kit'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import axios, { AxiosRequestConfig } from 'axios'
import { ENV_CS_API_URL } from 'lib/utils/env'

let _instance: CoreServiceApollo | null = null

class CoreServiceApollo {
  /**
   * The singleton Config instance.
   * @static
   * @return {CoreServiceApollo} Instance
   */
  static get() {
    if (!_instance) { _instance = new this() }
    return _instance
  }

  /**
   * Reset singleton instance. Config.get() will return a new instance next time called.
   * @static
   */
  static reset() {
    if (_instance) _instance.reset()
    _instance = null
  }

  _clientPublic: ApolloClient<NormalizedCacheObject> | null = null
  _clientMy: ApolloClient<NormalizedCacheObject> | null = null
  _inCacheMemory: InMemoryCache | null = null

  /**
   * constructor
   */
  constructor() {
    this._inCacheMemory = new InMemoryCache()
    this.configureClients()
  }

  /**
   * Returns the full url to the core service graphql server
   * @return {String} url
   */
  getCoreServiceGraphQLUrl() {
    return `${(ENV_CS_API_URL || '').replace(/\/$/, '')}/graphql`
  }

  /**
   * Returns the full url including the required scope param for public
   * @return {String} url
   */
  getCoreServiceGraphQLPublicUrl() {
    return [
      this.getCoreServiceGraphQLUrl(),
      'scope=vayapin_app_public',
    ].join('?')
  }

  /**
   * Returns the full url including the required scope param for my
   * @return {String} url
   */
  getCoreServiceGraphQLMyUrl() {
    return [
      this.getCoreServiceGraphQLUrl(),
      'scope=vayapin_app_my',
    ].join('?')
  }

  /**
   * Returns the apollo client
   * @return {ApolloClient} client
   */
  getPublicClient() {
    return this._clientPublic
  }

  /**
   * Returns the apollo client
   * @return {ApolloClient} client
   */
  getMyClient() {
    return this._clientMy
  }

  /**
   * Builds and sets the apollo client
   */
  configureClients() {
    this.configureClientPublic()
    this.configureClientMy()
  }

  /**
   * Builds and sets the public apollo client
   */
  configureClientPublic() {
    const [linkPublic, linkMyWithUpload] = this.getConfiguredLinks()

    // Based on context.api decide whether to use
    // the public API or my API
    const link = ApolloLink.split(
      (operation) => operation.getContext().api === 'my',
      linkMyWithUpload,
      linkPublic,
    )

    // Build the client
    this._clientPublic = new ApolloClient({
      cache: this._inCacheMemory as InMemoryCache,
      link,
    })
  }

  /**
   * Builds and sets the my apollo client
   */
  configureClientMy() {
    const [linkPublic, linkMyWithUpload] = this.getConfiguredLinks()

    // Based on context.api decide whether to use
    // the public API or my API
    const link = ApolloLink.split(
      (operation) => operation.getContext().api === 'public',
      linkPublic,
      linkMyWithUpload,
    )

    // Build the client
    this._clientMy = new ApolloClient({
      cache: this._inCacheMemory as InMemoryCache,
      link,
    })
  }

  /**
   * Create the conditions for link configuration for public and my API
   * also evaluated if there is a need for Upload fetching
   * @returns {(HttpLink|ApolloLink)[]}
   */
  getConfiguredLinks() {
    const linkMy = concat(
      this.getAuthApolloLink(),
      //
      // https://github.com/jaydenseric/apollo-upload-client/releases/tag/v18.0.0
      //
      createUploadLink({
        uri: this.getCoreServiceGraphQLMyUrl(),
        // @ts-ignore
        fetch: this.getAxiosFetchObject(),
      }) as unknown as ApolloLink
    )
    const linkPublic = new HttpLink({
      uri: this.getCoreServiceGraphQLPublicUrl(),
      // @ts-ignore
      fetch: this.getAxiosFetchObject(),
    })

    return [linkPublic, linkMy]
  }

  /**
   * axios fetch wrapper to get upload progress
   * for file submission
   * @returns {AxiosFetch}
   */
  getAxiosFetchObject() {
    return buildAxiosFetch<BuildFetchInitOptions>(axios, (config, _input, init) => ({
      ...config,
      onDownloadProgress: init?.onDownloadProgress,
      onUploadProgress: init?.onUploadProgress,
    }))
  }

  /**
   * Default link to ensure authentification
   * and JWT retrieving
   * @returns {ApolloLink}
   */
  getAuthApolloLink() {
    return new ApolloLink(
      // @ts-ignore
      async (operation, forward) => {
        await ApolloRequestHandler(operation)
        return forward(operation)
      },
    )
  }

  /**
   * Resets instance listeners
   */
  reset() {
    if (this._clientPublic) this._clientPublic.stop()
    if (this._clientMy) this._clientMy.stop()
    this._clientPublic = null
    this._clientMy = null
    this._inCacheMemory = null
  }
}

interface BuildFetchInitOptions extends RequestInit {
  onDownloadProgress?: AxiosRequestConfig['onDownloadProgress'];
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
}

export default CoreServiceApollo
