'use client'

import type { PropsWithChildren } from 'react'

import * as Sentry from '@sentry/nextjs'
import OAuthKit from '@vayapin/oauth-kit'
import OAuthKitReact from '@vayapin/oauth-kit-react'
import CookieStorage from '@vayapin/oauth-kit/storage/CookieStorage'
import LocalStorage from '@vayapin/oauth-kit/storage/LocalStorage'
import ClientConfig from 'lib/config/ClientConfig'

function debugHandler(message: string) {
  Sentry.addBreadcrumb({
    message,
    level: 'debug',
  })
  console.debug(message)
}

function exceptionHandler(error: Error) {
  Sentry.captureException(error)
  console.error(error)
}

OAuthKit.configure({
  clientId: ClientConfig.ID_OAUTH_ID ?? '',
  clientScope: 'vayapin_webapp',
  idHost: ClientConfig.ID_URL ?? '',
  idApiHost: ClientConfig.ID_API_URL ?? '',
  redirectUri: `${(ClientConfig.PUBLIC_URL ?? '').replace(/\/$/, '')}/oauth-callback`,
  authStorage: new CookieStorage({
    prefix: 'vp-wa',
    cookieSecure: ClientConfig.COOKIE_SECURE,
    sameSite: (key) => key === 'token' ? undefined : 'Strict',
    expires: (key) => {
      switch (key) {
        case 'token':
          return 2 // 2 days
        case 'refreshToken':
          return 2
        case 'authData':
          return 5
        case 'userData':
          return 5
        default:
          return undefined
      }
    }
  }),
  userInfoStorage: new LocalStorage({ prefix: 'vp-wa' }),
  debug: true,
  debugHandler,
  exceptionHandler,
})
OAuthKitReact.configure({})

function Provider({ children }: PropsWithChildren) {
  return (
    <>
      {children}
    </>
  )
}

export default Provider
