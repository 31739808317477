'use client'

import type { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#app')

function Provider({ children }: PropsWithChildren) {
  return (
    <>
      {children}
    </>
  )
}

export default Provider
