'use client'

import { memo, ReactNode, useMemo } from 'react'
import { useSignedIn } from 'services/client/User'

import {
  ApolloClient, ApolloProvider as Provider, InMemoryCache
} from '@apollo/client'

import CoreServiceApollo from './CoreServiceApollo'

export interface ApolloProviderProps {
  children?: ReactNode;
}

type Client = ApolloClient<InMemoryCache>

const ApolloProvider = memo<ApolloProviderProps>(({
  children,
}: ApolloProviderProps) => {
  const signedIn = useSignedIn()

  const client = useMemo<Client>(
    () => {
      const c: Client = signedIn
        ? CoreServiceApollo.get().getMyClient() as unknown as Client
        : CoreServiceApollo.get().getPublicClient() as unknown as Client

      return c
    },
    [signedIn]
  )

  // render
  return (
    <Provider client={client}>
      {children}
    </Provider>
  )
})
ApolloProvider.displayName = 'ApolloProvider'

export default ApolloProvider
