'use client'

import { type PropsWithChildren } from 'react'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import ClientConfig from 'lib/config/ClientConfig'

const ENV_ENABLED = ClientConfig.ENVIRONMENT === 'production' || ClientConfig.ENVIRONMENT === 'staging'

if (typeof window !== 'undefined' && ClientConfig.POSTHOG_KEY && ENV_ENABLED) {
  posthog.init(ClientConfig.POSTHOG_KEY, {
    api_host: '/ingest',
    ui_host: 'https://eu.posthog.com',
    person_profiles: 'identified_only',
  })
}

export default function AnalyticsProvider({
  children,
}: Readonly<PropsWithChildren>) {

  return ENV_ENABLED
    ? <PostHogProvider client={posthog}>{children}</PostHogProvider>
    : children
}
