'use client'

import { PropsWithChildren } from 'react'
import { Provider as OAuthKitProvider } from '@vayapin/oauth-kit-react'

function Provider({ children }: PropsWithChildren) {
  return (
    <OAuthKitProvider>
      {children}
    </OAuthKitProvider>
  )
}

export default Provider
